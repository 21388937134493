import Axios from 'axios'
import {Notification, Message} from 'element-ui'
import store from "@/store";
const service = Axios.create({ // url = base url + request url
    timeout: 60000, // 响应超时时间
})

// const STRING_NETWORK_LOADING = '加载中...';
// const STRING_NETWORK_ERROR = '网络连接错误，请刷新或稍后重试！';

// 添加请求拦截器
service.interceptors.request.use(
    (config) => {
        if (config.requestBody) {
            //config.data = qs.stringify(config.data);
            config.headers['Content-Type'] = 'application/json;charset=UTF-8'
            // config.data = qs.stringify(config.data)
        }
        if (store.getters.token){
            config.headers['Authorization'] = store.getters.token
        }
        return config
    },
    (error) => {
        return Promise.reject(error)
    }
)
// 添加响应拦截器
service.interceptors.response.use(
    // 服务器状态码：以2开头的的情况
    (response) => {
        if (response.status === 200) {
            const res = response.data
            if (res.success) {
                return Promise.resolve(res)
            } else {
                Message.error(res.errMessage)
                return Promise.reject(response.data)
            }
        } else {
            return Promise.reject(response)
        }
    },
    (error) => {
        let code = 0
        try {
            code = error.response.status
        } catch (e) {
            if (error.toString().indexOf('Error: timeout') !== -1) {
                // Notification.error({
                //   title: '网络请求超时',
                //   duration: 5000,
                // })
                return Promise.reject(error)
            }
        }
        if (code) {
            if (code === 401) {
                Notification.error({
                    title: '当前用户未授权',
                    duration: 5000,
                })
                setTimeout(()=>{
                    store.commit("logout")
                },2000)
                return Promise.reject(error)
            }
            if (code === 500) {
                Notification.error({
                    title: '服务异常，请重试',
                    duration: 5000,
                })
                return Promise.reject(error)
            }
        } else {
            // Notification.error({
            //   title: '接口请求失败',
            //   duration: 5000,
            // })
        }
        return Promise.reject(error)
    }
)

export default service;
